'use client'

import type {PropsWithChildren} from 'react';

export default function Template({children}: PropsWithChildren) {
  return (
    <div>
      {children}
    </div>
  )
}
